import React, { Suspense, lazy } from "react";
import { Switch, Route, Redirect, Router } from "react-router-dom";
import { history } from "./store/index";
import * as routeTo from "./utils/routeConstants";
import PrivateRoute from "./utils/PrivateRoute";
import DsaPage from "./Pages/DSA/DsaPage";
import { CoverWrapper } from "./Pages/Login/LoginStyle";

const LoginPage = lazy(() => import("./Pages/Login/LoginPage"));
const AdminPage = lazy(() => import("./Pages/DashBoard/AdminPage"));
const PosListPage = lazy(() => import("./Pages/PosTable/PosListPage"));
// const PosApprovalListPage = lazy(() => import('./Pages/PosTable/PosApprovalListPage'));
const PosEligibilityCheckPage = lazy(() =>
  import("./Pages/PosEligibilityCheck/PosEligibilityCheckPage")
);
const PolicySoldPage = lazy(() => import("./Pages/PolicySold/PolicySoldPage"));
const PosOnboardingPage = lazy(() =>
  import("./Pages/PosOnboardingList/PosOnboardingPage")
);
const DsaDetailPage = lazy(() =>
  import("./Pages/DSA/DsaDetails/DsaDetailsPage")
);
const AddDsaPage = lazy(() => import("./Pages/DSA/AddDsa/AddDsaPage"));
const OfflineProposalPage = lazy(() =>
  import("./Pages/OfflineProposal/CreateRevamp/OfflineProposalPage")
);
const OfflineProposalListPage = lazy(() =>
  import("./Pages/OfflineProposal/List/OfflineProposalListPage")
);
const GenerateOtpPage = lazy(() => import("./Pages/CreateOtp/GeneratePage"));
const PospDetailPage = lazy(() => import("./Pages/PospDetail/PospDetailPage"));
const PolicyDetailsPage = lazy(() =>
  import("./Pages/PolicyDetails/PolicyDetailsPage")
);
const GlobalCommissionPage = lazy(() =>
  import("./Pages/GlobalCommission/GlobalCommissionPage")
);
const GlobalChainCommissionPage = lazy(() =>
  import("./Pages/GlobalChainCommission/GlobalChainCommissionPage")
);
const FixedPayoutPage = lazy(() =>
  import("./Pages/Payouts/Fixed/FixedPayoutPage")
);
const NotificationPage = lazy(() =>
  import("./Pages/Notification/NotificationPage")
);
const PayoutFrequencyPage = lazy(() =>
  import("./Pages/GlobalPayoutFrequency/PayoutFrequencyPage")
);
const SpecialUserCreatePage = lazy(() =>
  import("./Pages/SpecialUser/Create/SpecialUserCreatePage")
);
const SpecialUserListPage = lazy(() =>
  import("./Pages/SpecialUser/List/SpecialUserListPage")
);
const SpecialUserDetailPage = lazy(() =>
  import("./Pages/SpecialUser/Detail/SpecialUserDetailPage")
);
const DashboardAnalyticsPage = lazy(() => import("./Pages/DashboardAnalytics"));
const InspectionsPage = lazy(() => import("./Pages/Inspections"));
const InspectionsDetailsPage = lazy(() =>
  import("./Pages/Inspections/Details")
);

const router = (
  <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
    <Suspense
      fallback={
        <CoverWrapper>
          {" "}
          <div className="cover-spin"></div>
        </CoverWrapper>
      }
    >
      <Switch>
        <Route path={`/${routeTo.LOGIN}`} component={LoginPage} />
        <PrivateRoute path={`/${routeTo.APP_ROOT}`} component={AdminPage} />
        <PrivateRoute path={`/${routeTo.POS_LIST}`} component={PosListPage} />
        {/* <PrivateRoute path={POS_APPROVAL} component={PosApprovalListPage} /> */}
        <PrivateRoute
          path={`/${routeTo.POS_ELIGIBILITY_CHECK}`}
          component={PosEligibilityCheckPage}
        />
        <PrivateRoute
          path={`/${routeTo.POS_DETAIL}/:posId?`}
          component={PospDetailPage}
        />
        <PrivateRoute
          path={`/${routeTo.POLICY_SOLD}`}
          component={PolicySoldPage}
        />
        <PrivateRoute
          path={`/${routeTo.POS_ONBOARDING}`}
          component={PosOnboardingPage}
        />

        {/* DSA routing */}
        <PrivateRoute path={`/${routeTo.DSA_LIST}`} component={DsaPage} />
        <PrivateRoute
          path={`/${routeTo.DSA_DETAIL}/:dsaId?`}
          component={DsaDetailPage}
        />
        <PrivateRoute path={`/${routeTo.ADD_DSA}`} component={AddDsaPage} />
        <PrivateRoute
          path={`/${routeTo.OFFLINE_PROPOSAL}`}
          component={OfflineProposalPage}
        />
        <PrivateRoute
          path={`/${routeTo.OFFLINE_PROPOSAL_LIST}`}
          component={OfflineProposalListPage}
        />
        <PrivateRoute
          path={`/${routeTo.GENERATE_OTP}`}
          component={GenerateOtpPage}
        />
        <PrivateRoute
          path={`/${routeTo.POLICY_DETAILS}`}
          component={PolicyDetailsPage}
        />
        <PrivateRoute
          path={`/${routeTo.GLOBAL_COMMISSION}`}
          component={GlobalCommissionPage}
        />
        <PrivateRoute
          path={`/${routeTo.GLOBAL_CHAIN_COMMISSION}`}
          component={GlobalChainCommissionPage}
        />
        <PrivateRoute
          path={`/${routeTo.FIXED_PAYOUT}`}
          component={FixedPayoutPage}
        />
        <PrivateRoute
          path={`/${routeTo.NOTIFICATION}`}
          component={NotificationPage}
        />
        <PrivateRoute
          path={`/${routeTo.PAYOUT_FREQUENCY}`}
          component={PayoutFrequencyPage}
        />
        <PrivateRoute
          path={`/${routeTo.CREATE_SPECIAL_USER}`}
          component={SpecialUserCreatePage}
        />
        <PrivateRoute
          path={`/${routeTo.SPECIAL_USER_DETAIL}/:userId?`}
          component={SpecialUserDetailPage}
        />
        <PrivateRoute
          path={`/${routeTo.SPECIAL_USER}`}
          component={SpecialUserListPage}
        />
        {/* <PrivateRoute path={`/pos-detail/:posId`} component={PospDetailPage} /> */}
        {/* Dashboard Analytics Routes */}
        <PrivateRoute
          path={`/${routeTo.DASHBOARD_ANALYTICS}`}
          component={DashboardAnalyticsPage}
        />
        <PrivateRoute
          path={`/${routeTo.INSPECTIONS}`}
          component={InspectionsPage}
        />

        <PrivateRoute
          path={`/${routeTo.INSPECTIONS_DETAILS}/:proposalId`}
          component={InspectionsDetailsPage}
        />
        <Route path="/" render={() => <Redirect to={`/${routeTo.LOGIN}`} />} />
      </Switch>
    </Suspense>
  </Router>
);

export default router;
