import * as routeTo from "../utils/routeConstants";
import PosList from "./PosTable/PosList";
import PosEligibilityCheckPage from "./PosEligibilityCheck/PosEligibilityCheckPage";
import PosOnboardingPage from "./PosOnboardingList/PosOnboardingPage";
import DsaPage from "./DSA/DsaPage";
import AddDsaPage from "./DSA/AddDsa/AddDsaPage";
import OfflineProposalPage from "./OfflineProposal/CreateRevamp/OfflineProposalPage";
import GenerateOtpPage from "./CreateOtp/GeneratePage";
import NotificationPage from "./Notification/NotificationPage";
import DialpadIcon from "@material-ui/icons/Dialpad";
import PolicySoldPage from "./PolicySold/PolicySoldPage";
import GlobalCommissionPage from "./GlobalCommission/GlobalCommissionPage";
import GlobalChainCommissionPage from "./GlobalChainCommission/GlobalChainCommissionPage";
import PayoutFrequencyPage from "./GlobalPayoutFrequency/PayoutFrequencyPage";
import SpecialUserCreatePage from "./SpecialUser/Create/SpecialUserCreatePage";
import SpecialUserListPage from "./SpecialUser/List/SpecialUserListPage";
import FixedPayoutPage from "./Payouts/Fixed/FixedPayoutPage";
import DashboardAnalyticsPage from "./DashboardAnalytics";
import Inspections from "./Inspections";

import ListAltIcon from "@material-ui/icons/ListAlt";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import ViewListIcon from "@material-ui/icons/ViewList";
import LanguageIcon from "@material-ui/icons/Language";
import BorderColor from "@material-ui/icons/BorderColor";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PeopleIcon from "@material-ui/icons/People";
import { DashboardOutlined } from "@material-ui/icons";

const componentRoute = [
  // {
  //   path: "dashboard",
  //   name: "Dashboard",
  //   icon: Dashboard,
  //   component: DashBoardComponent,
  //   layout: "/"
  // },
  {
    path: routeTo.POS_LIST,
    name: "Point of Sale List",
    icon: ListAltIcon,
    component: PosList,
    layout: "/",
    groupBy: "POSP",
  },
  {
    path: routeTo.POS_ELIGIBILITY_CHECK,
    name: "POSP Eligibility Check",
    icon: ListAltIcon,
    component: PosEligibilityCheckPage,
    layout: "/",
    groupBy: "POSP",
  },
  {
    path: routeTo.POS_ONBOARDING,
    name: "POSP Onboarding List",
    icon: ListAltIcon,
    component: PosOnboardingPage,
    layout: "/",
    groupBy: "POSP",
  },

  {
    path: routeTo.FIXED_PAYOUT,
    name: "Fixed Payout",
    icon: MoneyOffIcon,
    component: FixedPayoutPage,
    layout: "/",
    groupBy: "Payout",
  },

  // {
  //   path: routeTo.DSA_LIST,
  //   name: "DSA List",
  //   icon: ViewListIcon,
  //   component: DsaPage,
  //   layout: "/",
  //   groupBy: "DSA",
  // },

  // {
  //   path: routeTo.ADD_DSA,
  //   name: "Add DSA",
  //   icon: AddBoxIcon,
  //   component: AddDsaPage,
  //   layout: "/",
  //   groupBy: "DSA",
  // },

  {
    path: routeTo.GLOBAL_COMMISSION,
    name: "Commission",
    icon: LanguageIcon,
    component: GlobalCommissionPage,
    layout: "/",
    groupBy: "Global",
  },

  {
    path: routeTo.GLOBAL_CHAIN_COMMISSION,
    name: "Chain Commission",
    icon: LanguageIcon,
    component: GlobalChainCommissionPage,
    layout: "/",
    groupBy: "Global",
  },

  {
    path: routeTo.PAYOUT_FREQUENCY,
    name: "Payout Frequency",
    icon: LanguageIcon,
    component: PayoutFrequencyPage,
    layout: "/",
    groupBy: "Global",
  },

  {
    path: routeTo.CREATE_SPECIAL_USER,
    name: "Create User",
    icon: AccountCircleIcon,
    component: SpecialUserCreatePage,
    layout: "/",
    groupBy: "None",
  },

  {
    path: routeTo.SPECIAL_USER,
    name: "User List",
    icon: PeopleIcon,
    component: SpecialUserListPage,
    layout: "/",
    groupBy: "None",
  },
  {
    path: routeTo.DASHBOARD_ANALYTICS,
    name: "Dashboard",
    icon: DashboardOutlined,
    component: DashboardAnalyticsPage,
    layout: "/",
    groupBy: "None",
  },

  {
    path: routeTo.INSPECTIONS,
    name: "Inspections",
    icon: BorderColor,
    component: Inspections,
    layout: "/",
    groupBy: "None",
  },
  {
    path: routeTo.OFFLINE_PROPOSAL,
    name: "Offline Sales",
    icon: CreateNewFolderIcon,
    component: OfflineProposalPage,
    layout: "/",
    groupBy: "None",
  },
  {
    path: routeTo.POLICY_SOLD,
    name: "Policy Sold",
    icon: ViewListIcon,
    component: PolicySoldPage,
    layout: "/",
    groupBy: "None",
  },

  // {
  //   path: routeTo.OFFLINE_PROPOSAL_LIST,
  //   name: "Offline Proposals",
  //   icon: "content_paste",
  //   component: OfflineProposalListPage,
  //   layout: "/",
  //   groupBy: "Offline-Proposal"
  // },
  {
    path: routeTo.NOTIFICATION,
    name: "Notifications",
    icon: NotificationsActiveIcon,
    component: NotificationPage,
    layout: "/",
    groupBy: "None",
  },

  {
    path: routeTo.GENERATE_OTP,
    name: "Generate OTP",
    icon: DialpadIcon,
    component: GenerateOtpPage,
    layout: "/",
    groupBy: "None",
  },
];

export default componentRoute;
