/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
// core components
import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'
import PosTable from './PosTable'
import Card from '../../components/Card/Card'
import CardHeader from '../../components/Card/CardHeader'
import CardBody from '../../components/Card/CardBody'
import Search from '@material-ui/icons/Search'
import CustomInput from '../../components/CustomInput/CustomInput'
import Button from '../../components/CustomButtons/Button'
import styleHeader from '../../assets/jss/componentsStyles/headerLinksStyle'
import { isEmpty, exportCsv, getStatus } from '../../utils/common'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import FuzzySearch from 'fuzzy-search';
import Tooltip from '@material-ui/core/Tooltip';
import EcoIcon from '@material-ui/icons/Eco';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import moment from 'moment'


const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0'
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF'
    }
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: 'Montserrat',
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1'
    }
  },
  inputSearchType: {
    color: 'white',
    padding: '0'
  },
  paddingStyle: {
    padding: '0 12px 0'
  }
}
function createData(fullName, phone, pincode, panNo, approvalStatus, _id) {
  return { fullName, phone, pincode, panNo, approvalStatus, _id }
}

const useStyles = makeStyles(styles)
const headerStyle = makeStyles(styleHeader)

export default function PosList(props) {
  const classes = useStyles()
  const headerClass = headerStyle()
  const { posList = [], getDetails, uploadFile } = props
  const rows = []
  // const detailButton =  (<Button color="primary">Details</Button>)
  posList.forEach(pos => {
    let panNo = pos.panNo || '';
    // const {personalInfo = {}} = pos;
    const { personalInfo: { documentsUploaded } = [] } = pos;
    // if (!isEmpty(documentsUploaded)) {
    //   const doc = documentsUploaded.find(val => val.documentSubType === 'aadhar_front');
    // }
    const status = getStatus(pos);
    const row = createData(pos.fullName, pos.phone, pos.pinCode, panNo, status, pos._id)
    rows.push(row)
  })



  const [filteredList, setList] = React.useState()

  const [showInput, toggleInput] = React.useState(false)

  React.useEffect(() => {
    setList(rows) // api.data.count from api after update store
  }, [posList])
  // let newList = [...rows];

  React.useEffect(() => { document.getElementById('searchInput') && document.getElementById('searchInput').focus() }, [showInput])

  const filterList = (e) => {
    let currentList = []
    let newList = []

    // If the search bar isn't empty
    if (e.target.value !== '') {
      // Assign the original list to currentList
      currentList = rows
      const searcher = new FuzzySearch(currentList, ['fullName', 'phone', 'pincode', 'panNo', 'approvalStatus', '_id'], {
        caseSensitive: false
      })
      newList = searcher.search(e.target.value.toLowerCase())
    } else {
      // If the search bar is empty, set newList to original task list
      newList = [...rows]
    }

    setList(newList)
  }

  const getDataToExport = () => { 

    let dataToReturn = [];

    posList.filter(pos => !isEmpty(pos.fullName)).forEach(pos => {
      const {
        bankInfo = {},
        businessInfo = {},
        certification = {},
        educationInfo = {},
        personalInfo = {},
        email = '',
        fullName = '',
        phone = '',
        pinCode = '',
        roleCode = '',
        partnerCode='',
        accountCode="",
        approvalStatus="",
        signupDate="",
        onboardedDate="",
        referredBy={}
      } = pos;

      let aadharNo = personalInfo?.documentsUploaded?.find(du=>du.documentSubType==="aadhar_front")?.documentNumber || "",
          panNo = personalInfo?.documentsUploaded?.find(du=>du.documentSubType==="pan_front")?.documentNumber || "",
          dob = "" ;
      
      if(personalInfo.dob){
        dob = moment(parseInt(personalInfo.dob)).format("DD/MM/YYYY");
      }

      dataToReturn.push({
        "Name": fullName,
        "Phone no": phone,
        "Email ID": email,
        "Date of birth": dob,
        "Gender": personalInfo?.gender || "",
        "Marital status": personalInfo?.maritalStatus || "",
        "Aadhaar no": aadharNo,
        "PAN no": panNo,
        "Address": personalInfo?.address || "",
        "City": personalInfo?.city || "",
        "State": personalInfo?.state || "",
        "Pincode": pinCode,
        "Referred by": (referredBy?.fullName || "") + (referredBy?.phone ? ` (${referredBy?.phone})` :""
        ),
        "Partner name": accountCode.length>0? "TBO":"",
        "Partner user code": accountCode,
        "Highest qualification": educationInfo?.highestQualification || "",
        "Year of completion": educationInfo?.yearOfCompletion || "",
        "Bank Account holder name": bankInfo?.accountHolderName || "",
        "Bank Account No": bankInfo?.bankAccountNo || "",
        "Bank IFSC code": bankInfo?.ifscCode || "",
        "Exam passed?": certification?.isExamPassed || false,
        "Onboarding status": approvalStatus,
        "POSP Signed-up on": signupDate.length>0 ? moment(parseInt(signupDate)).format("DD/MM/YYYY") : signupDate ,
        "POSP Onboarded on": onboardedDate.length>0 ? moment(parseInt(onboardedDate)).format("DD/MM/YYYY") : onboardedDate
      })
    });
     
    return dataToReturn;
  }
  const exportPosp = (event) => {
    event.preventDefault()
 
    const data =  getDataToExport();

    const param = {
      fileName: window.env.BROKER_NAME + '_POSP_List_' + new Date().toISOString().split("T")[0].split("-").join(""),
      title: 'POSP List',
      data
    }
    
    return exportCsv(param)
  }

  const exportSampleUploadCsv = (event) => {
    event.preventDefault();
    const param = {
      fileName: 'POSP_UPLOAD',
      title: 'POSP List',
      data: [{ broker_id: '', phone_no: '', pan_no: '' }]
    };
    return exportCsv(param);
  }


  const headCells = [
    { id: 'fullName', label: 'Name' },
    { id: 'phone', label: 'Phone No' },
    { id: 'pinCode', label: 'Pincode' },
    { id: 'panNo', label: 'PAN Number' },
    { id: 'approvalStatus', label: 'Status' },
    { id: '_id', label: 'Details' }]

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <span style={{ float: 'left' }}>
              <h4 className={classes.cardTitleWhite}>POSP List</h4>

            </span>
            <span className={headerClass.searchWrapper} style={{ float: 'right' }}>
              {showInput && <CustomInput
                id="searchInput"
                formControlProps={{
                  className: classNames(headerClass.margin, headerClass.search)
                }}
                inputProps={{
                  inputProps: {
                    'aria-label': 'Search',
                    className: classes.inputSearchType
                  }
                }}
                onChange={(event) => filterList(event)}
              />}
              <Button className={classes.paddingStyle} color="white" aria-label="edit" simple onClick={() => toggleInput(!showInput)}>
                <Search /> {' '}Search
              </Button>

              <Button color="white" className={classes.paddingStyle} aria-label="edit" simple onClick={(event) => exportPosp(event)}>
                <ImportExportIcon /> {' '} Export
              </Button>
              <Tooltip title="Download Sample file to upload Posp List">
                <Button color="white" className={classes.paddingStyle} aria-label="edit" simple onClick={(event) => exportSampleUploadCsv(event)}>
                  <EcoIcon /> {' '} Download Sample
              </Button>
              </Tooltip>

              <Tooltip title="Upload Posp List">
                <Button color="white" className={classes.paddingStyle} aria-label="edit" simple component="label" variant="contained">
                  <CloudUploadIcon /> {' '} Upload List
                <input style={{ display: 'none' }} type="file" id='file' name="file" onChange={uploadFile} />
                </Button>
              </Tooltip>
            </span>

          </CardHeader>
          <CardBody>

            <PosTable
              tableHeaderColor="primary"
              headCells={headCells}
              isSelectRequired={false}
              orderByParameter="name"
              orderAs="asc"
              rows={filteredList}
              getDetails={getDetails}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}
