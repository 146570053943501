const dev = {
  api: {
    REGION: "YOUR_DEV_API_GATEWAY_REGION",
    URL: "https://berlin4.iceinsurance.in",
    DASHBOARD_URL: window.env.DASHBOARD_URL,
    // URL: "http://localhost:8000",
    //  URL: 'https://staging.iceinsurance.in'
    //  URL: window.env.DEV_URL
  },
};

const prod = {
  api: {
    REGION: "YOUR_DEV_API_GATEWAY_REGION",
    URL: window.env.PROD_URL,
    DASHBOARD_URL: window.env.DASHBOARD_URL,
  },
};

const staging = {
  api: {
    REGION: "YOUR_DEV_API_GATEWAY_REGION",
    URL: window.env.STAG_URL,
    DASHBOARD_URL: window.env.DASHBOARD_URL,
  },
};

const config =
  process.env.REACT_APP_STAGE === "prod"
    ? prod
    : process.env.REACT_APP_STAGE === "stag"
    ? staging
    : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
};
