import React, { Fragment } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import styles from "assets/jss/componentsStyles/tableStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import { BreakinStatusMapper, BREAK_IN_STATUS } from "utils/constants";
import * as routeTo from "utils/routeConstants";
import { history } from "store";

import {
  TableBody,
  TableCell,
  TableRow,
  Button,
  Table,
  TablePagination,
} from "@material-ui/core";
import moment from "moment";

import EnhancedTableHead from "./EnhancedTableHead";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const useStyles = makeStyles(styles);

const InspectionsTable = (props) => {
  const {
    rows,
    orderAs = "desc",
    orderByParameter = "inspectionCreateEpoch",
    isSelectRequired = false,
    tableHeaderColor = "",
    headCells = [],
    tabName = "",
    page,
    totalCount,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = props;
  const classes = useStyles();
  const [order, setOrder] = React.useState(orderAs);
  const [orderBy, setOrderBy] = React.useState(orderByParameter);
  const [selected, setSelected] = React.useState([]);

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
    console.log("property", property);
  };

  const handleViewDetails = (row) => {
    history.push({
      pathname: `/${routeTo.INSPECTIONS_DETAILS}/${row.inspectionId}`,
      state: row,
    });
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const getInspectionStatus = (statusCode, companyId = null) => {
    const { type = "", value = "" } = BreakinStatusMapper.get(statusCode) || {};
    return {
      type,
      value:
        companyId == "6" && statusCode == 1 ? "Break-in ID unavailable" : value,
    };
  };

  return (
    <Fragment>
      <div className={classes.tableResponsive}>
        <Table className={classes.table} stickyHeader>
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={totalCount}
            isSelectRequired={isSelectRequired}
            tableHeaderColor={tableHeaderColor}
            headCells={headCells}
          />

          <TableBody>
            {stableSort(rows, getSorting(order, orderBy)).map((row) => {
              const isItemSelected = isSelected(row.name);
              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.inspectionCreateEpoch}
                  selected={isItemSelected}
                  className={classes.tableBodyRow}
                >
                  <TableCell className={classes.tableCell}>
                    {row["quoteName"]}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {row["posFullName"]}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <img src={row["companyLogo"]} style={{ height: "1.2em" }} />
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {moment
                      .unix(Math.round(row["inspectionCreateEpoch"] / 1000))
                      .format("DD-MM-YYYY h:mm A")}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {
                      getInspectionStatus(row["status"], row["companyId"])[
                        "value"
                      ]
                    }
                    <br />
                    {!row["isLapsed"] && (
                      <Button
                        color="primary"
                        size="small"
                        onClick={() => handleViewDetails(row)}
                      >
                        View
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          "aria-label": "previous page",
        }}
        nextIconButtonProps={{
          "aria-label": "next page",
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Fragment>
  );
};

InspectionsTable.propTypes = {
  rows: PropTypes.array.isRequired,
  orderAs: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderByParameter: PropTypes.string.isRequired,
  isSelectRequired: PropTypes.bool.isRequired,
  tableHeaderColor: PropTypes.string.isRequired,
  headCells: PropTypes.array.isRequired,
  totalCount: PropTypes.string,
};

export default InspectionsTable;
